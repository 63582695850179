@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


img[src*=".svg"] {
  width: 100%;
}

.card-image, .image-wrapper {
  min-height: 1px;
}

.main-nav {
  background-color: $white;
  padding: 0 7vw;
  height: 5rem;


  //@include position-fixed-top(3);
}

.outer-wrapper {
  margin-top: -203px;
}

.polygon-content {

  width: 400px;
  height: 400px;
  position: relative;

  .button {
    background-color: transparent;
    color: $black;
    border: 1px solid $black;
    padding: 0.85rem 3rem;
    -webkit-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    -moz-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    margin-bottom: 2rem;
    -webkit-transition: 0.5s ease-out;
    -moz-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    white-space: nowrap;

    &:hover {
      color: $light-blue;
      border: 1px solid $light-blue;
      background-color: transparent;
      -webkit-box-shadow: 5px 5px 0px 0px $light-blue;
      -moz-box-shadow: 5px 5px 0px 0px $light-blue;
      box-shadow: 5px 5px 0px 0px $light-blue;
    }

  }

  .clip-polygon {
    width: 100%;
    height: 100%;
    padding: 5rem;

  }

  .inner-polygon {
    @include absolute-center;
    text-align: center;

    h2 {
      font-weight: 700;
      font-size: 2.5rem;
    }
  }
}

.primary-bg {
  background-color: $white;
}

.clip-polygon {

  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);


  img {
    width: 100%;

  }


}


/* The Overlay (background) */


.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 0;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  opacity: 0;

  background-color: rgba(255, 255, 255, 1);
  overflow-y: hidden; /* Disable vertical scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  -webkit-box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 1);
  box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 1);
}

/* Position the content inside the overlay */
.overlay-content {
  margin-top: 5rem !important;
  //border-top: .06em solid #d3d3d3;
  position: relative;
  width: 100%; /* 100% width */
  /* 30px top margin to avoid conflict with the close button on smaller screens */

}

/* The navigation links inside the overlay */


/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: $dark-blue;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 600px) {
  .overlay, .sticky.is-stuck {
    position: absolute; 
  }
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 43px;
  height: 35px;
  position: relative;
  z-index: 3;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* Icon 4 */

#nav-icon4 {
  float: right;

}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 24px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 27px;
  left: 8px;
}

#nav-icon4.open span {
  background: #000;
}


.nav-text {
  float: right;

  margin-right: 4rem;

}


.header_nav, .header_nav a {
  font-size: 1.2rem;
  font-weight: 700;
  padding-top: 1rem;
}


.nav-wrapper {
  margin-left: 7vw !important;
  margin-right: 7vw !important;
  text-align: center;

  ul {
    display: inline-block;
    text-align: left;
  }
;
}


// Styles Start here


.about-content {
  margin-top: 3rem;
}


.read-more {
  color: $black;
  font-size: 0.9rem;
  font-weight: 700;
}

.see-all {
  margin-top: 3rem;

  .button {
    padding: 1em 3em;
    font-size: 1rem;
    white-space: nowrap;

  }
}

.home-insights {


  .article-cards {
    margin-top: 3rem;


    .card {
      position: relative
    }

    .card-section {
      position: absolute;
      bottom: 0;


    }

    h4 {
      font-weight: 700;
      color: $white;
    }

    .read-more {
      color: $white;
      font-size: 0.9rem;
      font-weight: 700;
    }


  }


}


.home-article {


  background-color: $dark-blue;
  color: white;
  position: relative;

  img {
    width: 100%;
  }


  .article-slide {
    position: relative;
  }

  .sub-headline {
    margin-bottom: 2rem;
  }

  .read-more a {
    color: $white;
    text-transform: uppercase;

  }

  .vertical-center {
    @include vertical-center;
  }


}


.industries {
  .text-wrapper {
    margin-top: 18rem;

    h1 {
      font-size: 5rem;
      font-weight: 700;
    }
  }

  .image-wrapper {
    margin-top: 2rem;
  }
}


.industries-content {
  margin-top: 4rem;

}


.industries-cards {


  .fal {
    margin-bottom: 1rem;


  }

  .button .fal {
    margin-bottom: 0;
  }

  .cell {

    color: $dark-blue;
    font-weight: normal;
    font-size: 1.2rem;
    padding: 1rem;


  }

  span {
    display: block;
  }

  span.service-text {
    font-weight: 400;
    font-size: 1rem;
  }
}

.career-cards {

  padding: 2rem 7vw 5rem 7vw;
  background-color: #E5E5E5;

  .news-title {
    font-weight: 700;
    font-size: 3rem;
    color: black;
  }

  .fal {
    margin-bottom: 1rem;


  }

  .card-section {
    margin-top: 5rem;
    color: $dark-blue;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 1rem;

    .cell {
      margin-top: 2rem;
    }
  }


  span.service-text {
    font-weight: 400;
    font-size: 1rem;
  }
}


.references-about {

  margin-top: 10rem;
  margin-left: 7vw;
  margin-right: 7vw;


  .industries-content {
    margin-top: 3rem;

  }


  .card {
    position: relative;
    margin-bottom: 0;
  }

  .case-study-cards {
    margin-top: 1rem;
  }

  .card-section {
    position: absolute;

    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    color: black;
  }

  img {
    filter: blur(2px);
    -webkit-filter: blur(2px)
  }

  .read-more {
    color: black;
    font-size: 0.9rem;
    font-weight: 700;

  }
}

.filter-by {
  display: block;
}

.references-client {


  padding-bottom: 10rem !important;
  background-color: #E5E5E5;


  .news-cards {
    margin-top: 3rem;

    h4 {
      font-weight: 700;
    }

    .read-more {
      color: $black;
      font-size: 0.9rem;
      font-weight: 700;
    }


  }
}


.contact-form textarea {
  height: 250px;
}

.contact-form [type='checkbox'] {
  margin: 0;
}

.locations {
  h4 {
    font-weight: 700;

  }

  .cell {
    margin-top: 2rem;
  }

  li a {
    color: $black;

    &:hover {
      color: $dark-blue;
    }
  }


}

.accordion-item {

  border-top: 1px solid black !important;

  &:first-child {
    border-top: none !important;
  }
}

.accordion-title {
  font-weight: 700;

  &::before {
    margin-top: -1rem;
  }


  &:hover, &:focus {
    color: $dark-blue;
    background-color: transparent;
  }
}


// General

h1 {
  font-size: 3rem;
  font-weight: 700;
}


.container {
  position: relative;
}

.grid-container {


  padding: 2rem 7vw 2rem 7vw;

  h2 {
    font-weight: 400;
    font-size: 2.2rem;
    font-family: Open-Sans;
  }

  .button {
    background-color: transparent;
    color: $black;
    border: 1px solid $black;
    padding: 0.85rem 3rem;
    -webkit-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    -moz-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    margin-bottom: 2rem;
    -webkit-transition: 0.5s ease-out;
    -moz-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out;

    &:hover {
      color: $light-blue;
      border: 1px solid $light-blue;
      background-color: transparent;
      -webkit-box-shadow: 5px 5px 0px 0px $light-blue;
      -moz-box-shadow: 5px 5px 0px 0px $light-blue;
      box-shadow: 5px 5px 0px 0px $light-blue;
    }

  }

}


.divider {
  border-bottom: 5px solid $primary-color;
  position: absolute;
  left: 48%;
  right: 48%;
  padding-top: 1.5rem;
}

.cnt-wht {
  background-color: $white
}

.cnt-gry {
  background-color: $light-gray;

}

.text-right, .text-left {
  position: relative;
}

.nav-right {
  @include vertical-center;
  right: 0;
}

.logo {
  //@include vertical-center;
  //left: 0;

  img {
    max-height: 4rem;

  }

}

.align-center {
  -webkit-box-pack: center;

  -webkit-justify-content: center;

  -ms-flex-pack: center;

  justify-content: center;
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

// Hero Module

.module-hero {
  background-color: rgba(195, 210, 229, 1);

  padding-top: 10rem;
  padding-bottom: 10rem;


  position: relative;

  .lead {
    margin-top: 2rem;
  }

  .vertical-center {
    @include absolute-center;
    width: 100%;
    text-align: center;
  }

  h1 {

  }

  #svg-container {

    filter: drop-shadow(0 6px 3px rgba(0, 0, 0, 0.5));
    @include absolute-center;
    width: 23rem;
    z-index: 3;
  }

}

.mod-hero-ndx {
  background-color: rgb(195, 210, 229);
  margin-top: 5rem;
  min-height: 95vh;
  position: relative;


  position: relative;

  .ttl-hero {
    @include vertical-center;
  }

  .sub-headline {
    padding: 3rem 2rem 2rem 0;
  }


  #svg-container {

    @include absolute-center;
    width: 70%;
    z-index: 3;
  }

}


.hero-text {

  position: relative;
}

.heroimg {


  position: relative;
}


.article-date-read {
  color: $black;
  text-align: center;
}

.hero-location {
  font-weight: 700;
  color: $black;
  text-align: center;
}

// Footer

.mod-footer {
  background-color: #565D65;


  ul {
    display: inline-block;
    text-align: left;
  }

  .header_nav {
    color: $white;
  }

  a {
    padding: 0.5rem 0;
    text-decoration: none;
    font-size: 1rem;
    color: $white;
    display: block;
  }
}

.mod-legal {
  background-color: $black;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $white;

  .large-8 {
    position: relative
  }

  .legal-menu {
    @include vertical-center;

    a {
      color: $white;
      font-size: 0.8rem;
      padding: 0.7rem 0.5rem;
    }
  }

  .social-menu {

    float: right;

    a {
      color: $white;
      font-size: 0.8rem;
      padding: 0.7rem 0;
    }
  }


  .small-legal {
    margin-top: 1rem;
    font-size: 0.8rem;
    border-top: 1px solid $white;
    padding-top: 1rem;
  }
}

// SEARCH BAR

.input-group-rounded {
  padding-top: 2rem;

  .input-group-field {
    border-radius: 5000px 0 0 5000px;

    padding: 1.5rem;
  }

  .input-group-button .button {
    border-radius: 0 5000px 5000px 0;
    font-size: 0.8rem;
  }
}

.search-form {
  .button {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  }
}

// Language Dropdown


#language-dropdown {

  padding: 0;


  ul {
    z-index: 4;
    text-align: left;

    overflow: overlay;
    margin-bottom: 0;
  }


  li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: 1px solid transparent;
    }


  }

  .current {
    font-weight: 800;
  }

  .select {
    background-color: $light-gray;
    padding: 0.25rem 1rem;
    font-size: 0.9rem;
  }

}

//Story


//Leadership


//News-Detail


$social-button-size: 3.125rem;
$social-button-border-width: 0.125rem;
$social-button-font-size: 1.5625rem;
$social-button-line-height: 2em;
$social-button-border-radius: 1.6875rem;
$social-button-transition: all 0.5s ease;
$social-button-margin: 0.75rem;

// Source: https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #0077b5;
$social-brand-xing: #026466;
$social-brand-weixin: #7bb32e;

@mixin social-button($brand-color, $brand-icon) {
  background: $brand-color;

  &:before {
    font-family: "Font Awesome 5 Brands";
    content: $brand-icon;
  }

  &:hover,
  &:focus {
    color: $brand-color;
    background: $white;
    border-color: $brand-color;
  }
}


.neat-article-container {


  .neat-article-header {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .article-header-avatar {
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      .header-avatar {
        width: rem-calc(60);
        height: rem-calc(60);
        border-radius: 50%;
      }
    }

    .article-header-author {
      flex: 1 0 0;

      .author-name {
        color: $black;
        margin-bottom: 0;
      }

      .author-description,
      .article-date-read {
        color: $dark-gray;
        margin-bottom: 0;
        font-size: 0.85em;
      }
    }
  }

  .neat-article-title {
    margin-top: 1rem;

    .article-title {
      color: $black;
      font-weight: 600;
    }
  }

  .neat-article-image {
    margin-top: 1.5rem;

    .article-image {
      width: 100%;
      height: auto;

      @include breakpoint(medium only) {
        height: 65vh;
      }

      @include breakpoint(small only) {
        height: 45vh;
      }
    }
  }

  .neat-article-content {


    h2 {
      font-size: 2rem;
    }

    .article-social {
      .rounded-social-buttons {
        text-align: center;

        .social-button {
          display: block;
          position: relative;
          cursor: pointer;
          width: $social-button-size;
          height: $social-button-size;
          border: $social-button-border-width solid transparent;
          padding: 0;
          text-decoration: none;
          text-align: center;
          color: $white;
          font-size: $social-button-font-size;
          font-weight: normal;
          line-height: $social-button-line-height;
          border-radius: $social-button-border-radius;
          transition: $social-button-transition;
          margin-right: $social-button-margin;
          margin-bottom: $social-button-margin;

          &:hover,
          &:focus {
            transform: rotate(360deg);
          }

          &.facebook {
            @include social-button($social-brand-facebook, "\f39e")
          }

          &.twitter {
            @include social-button($social-brand-twitter, "\f099")
          }

          &.google-plus {
            @include social-button($social-brand-linkedin, "\f0e1")
          }

          &.xing {
            @include social-button($social-brand-xing, "\f168")
          }

          &.weixin {
            @include social-button($social-brand-weixin, "\f1d7")
          }

          &.linkedin {
            @include social-button($social-brand-linkedin, "\f0e1")
          }
        }
      }
    }

    .article-content {
      color: $black;
    }
  }
}


h4 a {
  color: $black;
}

h2 a {
  color: $black;
}


.trademark-footer {
  font-size: 0.5rem;
  display: block;
}

.trademark-header {
  font-size: 1rem;
  font-weight: 700;
}

.breadcrumbs li {
  font-size: 1rem;
}

.breadcrumbs a {
  color: $black;
}


.mod-career-detail a {
  color: $black;
}

.mod-maps {


  #map {
    height: 600px; /* The height is 400 pixels */
    width: 100%; /* The width is the width of the web page */
  }
}

fieldset {
  padding-top: 2rem;
}

[type='checkbox'] + label {
  font-size: 1rem
}

.heroimg #new-container {

}


// Hero Testing
.mod-test {

  .orbit {
    background-color: rgb(195, 210, 229);
  }

  .orbit-container {


    overflow: visible;

    .text-wrapper {
      @include vertical-center;
    }

    .orbit-slide {
      position: relative;
      min-height: 750px;
      max-height: 1000px;
      height: 100vh;

    }

    .img-wrapper {

    }

    #new-container {

      position: absolute;
      right: 7vw;
      bottom: 0;
      width: 109.33333%;
      max-width: 40%;
      -webkit-transform: translateY(25%);
      transform: translateY(25%);
      overflow: hidden;


    }

    .sub-headline {
      padding: 3rem 2rem 2rem 0;
    }
  }

  .orbit-controls {
    left: 7vw;
    bottom: 4rem;

    position: absolute;
    z-index: 10;
    padding: 0 2rem;
    color: #fefefe;
  }

  .orbit-previous, .orbit-next {
    color: $black;
  }

  .orbit-next {
    left: auto;
    right: auto;
    margin-left: 2rem;
  }

  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: transparent;
  }
}

//.menu .has-dropdown > a {
//  font-size: 1.2rem;
//  font-weight: 800;
//
//}

.menu .sub-menu {
  list-style: none;
  margin-left: 0;
}

.current-menu-item .sub-menu {
  margin-left: 1rem;
}

.amcharts-map-image[aria-label="United States "] {
  fill: green;
}

g[aria-label="United States "] circle:hover {
  fill: $dark-blue !important;
}

.nav-text p {
  margin-bottom: 0;
}

.location-acc {
  margin-top: 3rem;
}

//FIX TO RELATIVE HEIGHT
.grid-container.mod-career-detail, .grid-container.mod-casestudy {
  padding-bottom: 7rem;
}


.mod-career-detail {
  .breadcrumbs {
    margin-bottom: 4rem;
  }


}

.print-button a span {
  color: $primary-color;
}

.iconrep i {
  color: $primary-color;
}

.stat {
  color: $primary-color;
}

.nav-text a {
  color: $black;

  &:hover {
    color: $dark-blue;
  }
}

.grid-container.mod-solutionshome {

  .see-all {
    margin-top: -3rem;
  }
}

.card-divider {
  a:hover {
    color: $primary-color !important;
  }
}

.cs.read-more {
  bottom: 1rem;
  position: absolute;
  left: 1rem;
}


.content-element {
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
}


//FIXME Adjust Padding for bigger screens
.top-bar {
  border-bottom: .06em solid #d3d3d3;

  height: 5rem;


  .top-bar-left {
    margin-left: 2.5rem;

    .menu a {
      color: $black;
      padding: 0.7rem 1.25rem;
    }
  }

  .top-bar-right {
    .menu a {
      color: $black;
      font-size: 0.9rem;


      .fa-2x {
        font-size: 1.5rem;
      }

      .nav_text_align {
        float: right;
        padding: 0 0 3px 7px;
      }
    }
  }
}


.description-section {
  padding-bottom: 2rem;
}


element.style {
  max-width: 1688px;
  margin-top: 0px;
  top: 0px;
  bottom: auto;
}


#job-eq a {
  color: $black;

  &:hover {
    color: $dark-blue;
  }
}

cite {
  font-size: 0.9rem;
  color: $white;
  margin-top: 1rem;
}


.career-title {
  margin-top: 4rem;
}

.map-bg {
  background-image: url('/wp-content/themes/kcg/assets/img/mapbg.jpg');
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  background-size: contain;

  .orbit-wrapper {
    height: 700px;
    position: relative;
  }

  .orbit-container {
    @include vertical-center;
    width: 100%;

  }

  .orbit-slide {
    text-align: center;

  }

  .small-hdln {
    font-size: 1.5rem;
    font-weight: 700;
    color: $black;

  }

  .big-hdln {
    font-weight: 700;
    font-size: 3rem;
    color: $dark-blue;

  }
}

//TOPBAR TESTING


.mega-menu {
  .dropdown-pane {
    width: 100%;
    max-width: 85rem;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
  }

  .menu {
    background: none;
  }

  > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    margin-left: 10px;
    margin-top: 11%;
  }
}


.overlay-content {
  .menu-item-has-children > a {
    padding-left: 1.75rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;

    &:after {
      content: '\f178';
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      margin-left: 10px;
      color: $dark-blue;
    }

    &:hover {
      color: $dark-blue;
    }
  }
}


.show {
  opacity: 1;
  height: auto;

}

.sticky-container {
  z-index: 3;
}

.icon-link {
  text-align: center;
  color: #000;
  font-weight: normal;
  font-size: 1.2rem;
  padding: 3rem;

  a {
    color: $black;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;

    p {
      color: #000;
      -webkit-transition: color 0.5s ease-out;
      -moz-transition: color 0.5s ease-out;
      -o-transition: color 0.5s ease-out;
      transition: color 0.5s ease-out;

      &:hover {
        color: $light-blue;

      }
    }


    &:hover {
      color: #1779ba;

    }
  }

  span {
    display: block;
  }
}

.sub-headline {
  padding-bottom: 1.5rem;
}

.icon-wrapper {
  position: relative;
}

.icon-container {
  @include vertical-center;
  right: 0;

  .fal {
    font-size: 1.5rem;
  }
}


.headline-content {
  padding-top: 4rem;
}

//Wrappers

.grey-wrapper {
  background-color: $light-gray;
}

.career-wrapper {
  background: url(../img/career.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-size: cover;
}

.hero-wrapper {

  padding-bottom: 0;

}

.hero-wrapper-grey {
  padding-bottom: 0;
  background-color: $light-gray;

}


.darkblue-wrapper {
  background-color: $dark-blue;


}

//CARDS

.cards-insights {


  h2 {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .text-container {
    position: relative;
    padding: 1rem;
  }

  .horizontal {
    flex-direction: row;

    .card-section {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      padding: 1rem;
      flex: 1 1 auto;
    }


    img {
      max-width: none;
    }

    .vertical-center {
      @include vertical-center;
    }
  }

  .read-more {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }

  .read-more a {
    color: $black;
    text-transform: uppercase;

  }
}

//Modules
.module-solutions_cat {
  margin: 2rem 0;
}

.module-cs-first {
  margin-bottom: 2rem;
}

.module-solutions {
  margin: 2rem 0;
}

.module-ethics {
  margin: 2rem 0;
}

.module-cs {
  margin: 2rem 0;
}

.module-solutions-single {
  margin: 2rem 0;
}

.module-icon_text {
  margin: 2rem 0;
}

.module-references {
  margin: 2rem 0;
}


.module-industries-single {
  .content-wrapper {
    padding-bottom: 0;
  }
}

.module-related-solutions {
  margin: 2rem 0;
}

.module-facts {
  margin: 2rem 0;
}

.module-insights {
  margin: 2rem 0;
}

.module-casestudy {
  margin: 2rem 0;

  h4 {

    font-size: 1.5rem;
    color: white;
    font-weight: bold;
  }

  .card {
    position: relative;
    margin-bottom: 0.83333rem;
    margin-top: 0.83333rem;
  }


  .card-section {
    position: absolute;


    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    color: black;
    padding: 0;

    .vertical-align {
      @include vertical-center;
      padding: 1rem;
    }
  }

  img {
    filter: blur(2px);
    -webkit-filter: blur(2px)
  }

  .read-more {
    color: white;
    font-size: 0.9rem;
    font-weight: 700;
  }


}

.module-news {
  margin: 2rem 0;
}


.module-about {
  margin: 2rem 0;
}


.module-carousel {
  margin: 2rem 0;
}

.module-locations {
  margin: 2rem 0;
}

.module-news, .module-jobs {


  .news-cards {

    h4 {
      font-weight: 700;
      margin-top: 0.5rem;
    }


    .news-card-date {
      font-size: 0.9rem;
    }


  }
}

.module-career {
  padding: 10rem 7vw 10rem 7vw !important;


}


.module-career-first {
  margin: 2rem 0;
}

.module-jobs {
  margin: 2rem 0;
}

.module-offering {
  margin: 2rem 0;
}

.module-hero {


  padding-top: 10rem;
  padding-bottom: 10rem;


  position: relative;

  .lead {
    margin-top: 2rem;
  }

  .vertical-center {
    @include absolute-center;
    width: 100%;
    text-align: center;
  }

  h1 {

  }

  #svg-container {

    @include absolute-center;
    width: 23rem;
    z-index: 3;
  }

}

.module-cta {

  margin: 2rem 0 4rem 0;


  a {
    color: black;
  }


  img {
    width: 100%

  }

  .contact-headline {
    margin-bottom: 3rem;
  }

  .text-wrapper {
    position: relative;
  }

  .vertical-center {

    @include absolute-center;
    white-space: nowrap;

  }

  .name {
    font-size: 2rem;
    font-weight: 700;
  }

  .title {
    font-size: 1.5rem;
  }

  .see-all {
    margin-top: 0;
  }

  .adress {

  }

  .adress span {
    display: block;
  }


  .button {
    background-color: black;
    color: white;
    border: 1px solid transparent;
    padding: 0.85rem 3rem;
    -webkit-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    -moz-box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    box-shadow: 5px 5px 0px 0px rgba(56, 76, 101, 1);
    margin-bottom: 2rem;
    white-space: nowrap;

    &:hover {
      border: 1px solid $light-blue;
      background-color: transparent;
    }

  }
}

.module-breadcrumb {
  padding: 2rem 7vw 2rem 7vw;
  background-color: $white;


  &.is-stuck {
    padding: 1rem 1rem 0rem 1rem;

    border-bottom: .06em solid #d3d3d3;
  }

  .sticky.is-anchored {
    z-index: -1;
  }

}

.module-selector {

  margin: 2rem 0;
  background-color: #fff;


  .article-cards {
    margin-top: 6rem;


    .card {
      position: relative
    }

    .card-section {
      position: absolute;
      bottom: 0;
    }

    h4 {
      font-weight: 700;
      color: $white;
    }

    .read-more {
      color: $white;
      font-size: 0.9rem;
      font-weight: 700;
    }


  }


}

.module-bigtext {
  margin: 2rem 0;

  .cs-testimonial-text {
    font-weight: 700;
    font-size: 2rem;
    color: white;
  }

  h2 {
    color: $white;
  }

  .divider {
    border-color: $white;
  }
}

.module-leadership {
  margin: 2rem 0;

  h4 {
    font-weight: 700;
  }

  .position {
    display: block;
  }


  .card-container {

    .cell {
      margin-bottom: 1rem;

      &:nth-child(-n+3) {
        margin-bottom: 3rem;
      }
    }
  }

  .card-section {
    border: none;
    padding: 0 1rem;

  }


  .horizontal {
    flex-direction: row;
    width: 385px;
    @include horizontal-center;

    img {
      max-width: none;
    }

    .card-section {
      flex: 1 1 auto;
      white-space: nowrap;


    }
  }

  .industries-content {
    margin-top: 4rem;

  }

}

.module-history {

  background-color: $white;
  $timeline-color: $dark-blue;
  $timeline-transition: all 0.25s ease-in;
  $timeline-item-card-bg: $white;
  $timeline-item-font-color: $black;
  $timeline-item-card: false;
  $timeline-item-card-radius: $global-radius;

  %clearfix {
    &::after,
    &::before {
      clear: both;
      content: '';
      display: block;
      width: 100%;
    }
  }

  // Timeline
  .timeline {
    margin: 30px auto;
    padding: 0 10px;
    position: relative;
    transition: $timeline-transition;
    width: 100%;

    &::before {
      background: $timeline-color;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 3px;
    }

    &::after {
      clear: both;
      content: '';
      display: table;
      width: 100%;
    }

    a {
      color: $timeline-item-font-color;
      font-weight: bold;
      transition: $timeline-transition;

      &:hover {
        box-shadow: 0 1px 0px 0px $timeline-item-font-color;
        transition: $timeline-transition;
      }
    }

    .timeline-item {
      margin-bottom: 50px;
      position: relative;
      @extend %clearfix;

      .timeline-icon {
        background: $timeline-color;
        border-radius: 50%;
        height: 100px;
        left: 50%;
        margin-left: -48px;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100px;

        img,
        svg, i {
          color: $white;
          font-size: 2rem;
          @include absolute-center;
        }


      }

      .timeline-content {
        padding: 20px;
        text-align: right;
        transition: $timeline-transition;
        width: 45%;

        @if $timeline-item-card == true {
          background: $timeline-item-card-bg;
          border-radius: $timeline-item-card-radius;

          p {
            color: $black;
          }
        } @else {
          background: transparent;

          p {
            color: $timeline-item-font-color;
          }
        }

        p {
          font-size: 1.2rem;

          margin-bottom: 0;
        }

        img {
          margin-top: 1rem;
          width: 200px;
        }

        .timeline-content-date {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
        }

        .timeline-content-month {
          font-size: rem-calc(14);
          text-transform: uppercase;
          font-weight: 400;
        }

        &.right {
          float: right;
          text-align: left;
        }
      }
    }
  }

  @include breakpoint(small only) {
    .timeline {
      margin: 30px;
      padding: 0;
      width: 90%;

      &::before {
        left: 0;
      }

      .timeline-item {
        .timeline-content {
          float: right;
          text-align: left;
          width: 90%;

          &::before,
          &.right::before {
            border-left: 0;
            border-right: 7px solid $timeline-color;
            left: 10%;
            margin-left: -6px;
          }
        }

        .timeline-icon {
          left: 0;
        }
      }
    }
  }


}

.module-article {
  padding-top: 2rem;
}


//FIXES
.overlay .menu-item-object-page {
  padding-bottom: 2rem;


}

.overlay .sub-menu .menu-item-object-page {
  padding-bottom: 0;
}

.divider-wrapper {
  padding-top: 0rem;
  padding-bottom: 5rem;
}

.content-wrapper {

  padding-bottom: 3rem;
}

.header-caption {
  @include vertical-center;
  top: 53%;
  left: 0;
  padding-left: 6.5%;
  width: 50%;
  color: $white;

  span {
    display: block;
  }

  .headline-big {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
  }

  .headline-small {
    font-size: 1.1rem;
    font-family: Open-Sans;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .button {
    background-color: $white;
    color: $black;
    border: 1px solid $black;
    padding: 0.85rem 3rem;
    -webkit-box-shadow: 5px 5px 0px 0px $black;
    -moz-box-shadow: 5px 5px 0px 0px $black;
    box-shadow: 5px 5px 0px 0px $black;
    margin-bottom: 2rem;
    -webkit-transition: 0.5s ease-out;
    -moz-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out;

    &:hover {
      color: $light-blue;
      border: 1px solid $light-blue;
      background-color: $white;
      -webkit-box-shadow: 5px 5px 0px 0px $light-blue;
      -moz-box-shadow: 5px 5px 0px 0px $light-blue;
      box-shadow: 5px 5px 0px 0px $light-blue;
    }
  }
}


.nav-main {
  z-index: 5;
}

.sortby {

  font-size: 1rem;
  color: $black;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #cecdcd;

  &:hover {
    color: $light-blue;
  }


}

.fst-sol {
  & > a {
    font-size: 1.4rem !important;
    padding-bottom: 2rem;
  }

}

.manager-detail:not(:first-child) {

  margin-top: 2rem;
}

.mngr-name {
  font-size: 1.1rem;
}

.claim {
  padding-top: 1rem;

}

